import TransportLayer, { SignUpUserData } from './TransportLayer';

export class AccountStore {
  constructor(private readonly transportLayer: TransportLayer) {
    //makeObservable(this);
  }

  async register(user: SignUpUserData): Promise<string> {
    return this.transportLayer.signUp(user);
  }

  async activateAccount(activationId: string): Promise<boolean> {
    return this.transportLayer.activateUser(activationId);
  }
}

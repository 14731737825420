import { makeStyles, ThemeProvider, withTheme } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import GoogleFontLoader from 'react-google-font-loader';
import { Route, Switch } from 'react-router-dom';
import { MyAppBar } from './components/MyAppBar';
import theme from './Theme';
import Home from './views/public/Home';
import { Lookup } from './views/public/Lookup';
import { SignIn } from './views/public/SignIn';
import { SignUp } from './views/public/SignUp';
import { PebbleIndex } from './views/public/components/PebbleIndex';
import { MyIndex } from './views/my/MyIndex';
import { Box } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GlobalCss } from './GlobalCss';
import { AccountActivation } from './views/public/AccountActivation';

const useStyles = makeStyles((theme) => ({
  root: {
    //	flexGrow: 1
  },
  appBar: {
    backgroundColor: 'white',
    color: theme.palette.text.primary
  },
  title: {
    //flexGrow: 1
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10
  }
}));

const App = withTheme(
  inject('rootStore')(() => {
    const classes = useStyles();
    // const [cookieConsentOpen, setCookieConsentOpen] = useState(false);
    // const [cookiePreferencesDialogOpen, setCookiePreferencesDialogOpen] = useState(false);
    //const [cookiePreferences, setCookiePreferences] = useState<CookiePreferences>(new CookiePreferences());

    // useEffect(() => {
    // 	if (!cookiePreferences.load())
    // 		setCookieConsentOpen(true);
    // }, []);

    // const handleCookieAccept = () => {
    // 	cookiePreferences.acceptAllAndSave();
    // 	setCookieConsentOpen(false);
    // }
    // const showCookiePreferences = () => {
    // 	setCookiePreferencesDialogOpen(true);
    // }
    // const handleCookieDialogClose = () => {
    // 	setCookieConsentOpen(false);
    // 	setCookiePreferencesDialogOpen(false);
    // }

    return (
      <>
        <GoogleFontLoader
          fonts={[
            {
              font: 'Roboto',
              weights: [300, 400, 500, 700]
            }
          ]}
        />
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalCss />

            <MyAppBar />

            <Box className="page-content">
              <Switch>
                {/* <Route path="/" component={SoonAvailable} /> */}
                <Route exact={true} path="/" component={Home} />
                <Route exact={true} path="/activate" render={() => <AccountActivation />} />
                <Route exact={true} path="/sign-in" component={SignIn} />
                <Route exact={true} path="/sign-up" component={SignUp} />
                <Route exact={true} path="/get-started" component={() => <Home getStartedDialogOpen={true} />} />
                <Route exact={true} path="/lookup/:code" render={(p) => <Lookup code={p.match.params.code} />} />
                <Route exact={true} path="/pebbles/:id" render={(p) => <PebbleIndex id={p.match.params.id} />} />
                {/*<Route exact={true} path="/about-us/privacy-statement" component={PrivacyStatement} />
							<Route exact={true} path="/about-us/terms-of-use" component={TermsOfUse} />
							<Route exact={true} path="/about-us/cookie-policy" component={CookiePolicy} />
							<Route exact={true} path="/about-us/contact" component={Contact} />
							<Route exact={true} path="/password-reset/:id" render={props => <ResetPassword id={props.match.params.id} />} />
							<Route exact={true} path="/signin" component={SignIn} /> */}
                {/* <Route exact={true} path="/events/new" component={SoonAvailable} /> */}
                {/* <Route exact={true} path="/event/create" render={props => <NewEvent />} /> */}
                {/* <Route exact={true} path="/events/:eventId/register" render={props => <EventRegistration eventId={props.match.params.eventId} />} />*/}
                <Route exact={false} path="/my/" component={MyIndex} />
              </Switch>
            </Box>

            {/* <Switch>
						<Route exact={true} path="/event/create" />
						<Route exact={true} path="/events/:eventId/register" />
						<Route exact={false} render={props => <Footer onCookieSettingsClick={showCookiePreferences}/> } />
					</Switch>
					<CookieConsent 
						open={cookieConsentOpen}
						onConfirm={handleCookieAccept}
						onCustomize={showCookiePreferences}/>
					<CookiePreferencesDialog 
						open={cookiePreferencesDialogOpen}
						cookiePreferences={cookiePreferences}
						onClose={handleCookieDialogClose} 
					/>*/}
          </ThemeProvider>
        </div>
      </>
    );
  })
);

export { App };

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { inject } from 'mobx-react';
import { useState } from 'react';
import RootStore from '../../../stores/RootStore';
import { SignInDialog } from '../../../components/SignInDialog';

interface IProps {
  rootStore?: RootStore;
  open: boolean;
  onClose: () => void;
}

const LogCompletedDialog = inject('rootStore')((props: IProps) => {
  const [signinDialogOpen, setSigninDialogOpen] = useState<boolean>(false);

  const onSigninClick = () => {
    setSigninDialogOpen(true);
  };
  const onAnonymousClick = () => {
    props.onClose();
  };
  const onOkClick = () => {
    props.onClose();
  };
  const onSigninDialogClose = () => {
    setSigninDialogOpen(false);
    if (props.rootStore!.authStore.isAuthenticated) {
      props.onClose();
    }
  };

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Loggen</DialogTitle>
        <DialogContent>
          {!props.rootStore!.authStore.isAuthenticated && (
            <Box mt={2}>
              <Box>
                <Typography>Heb je al een account en wil je deze gevonden steen toevoegen aan je collectie van stenen? Log dan in.</Typography>
              </Box>
              <Box mt={2}>
                <Typography>Nog geen account? Maak dan een gratis account en wij voegen deze eerste steen direct voor je toe.</Typography>
              </Box>
              <Box mt={2}>
                <Typography>Liever anoniem loggen, dat mag natuurlijk ook.</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {!props.rootStore!.authStore.isAuthenticated && (
            <>
              <Button variant="contained" color="primary" onClick={onSigninClick}>
                Inloggen of account aanmaken
              </Button>
              <Button variant="outlined" onClick={onAnonymousClick}>
                Anoniem Loggen
              </Button>
            </>
          )}
          {props.rootStore!.authStore.isAuthenticated && (
            <Button variant="contained" color="primary" onClick={onOkClick}>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SignInDialog open={signinDialogOpen} onClose={onSigninDialogClose}></SignInDialog>
    </>
  );
});

export { LogCompletedDialog };

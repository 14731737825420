import { Box, Container, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import RootStore from '../stores/RootStore';

interface IProps {
  rootStore?: RootStore;
}

const AuthenticatedArea = inject('rootStore')(
  observer((props: React.PropsWithChildren<IProps>) => (
    <>
      {props.rootStore!.authStore.isAuthenticated && props.children}
      {!props.rootStore!.authStore.isAuthenticated && (
        <>
          <Container maxWidth="md">
            <Box className="page-title">
              <Typography variant="h4">Inloggen vereist</Typography>
            </Box>
          </Container>
        </>
      )}
    </>
  ))
);

export { AuthenticatedArea };

// @inject('rootStore')
// @observer
// class AuthenticatedArea extends React.Component<IProps, {}> {

// 	render() {
// 		return (
// 			<>
// 				{this.props.rootStore!.authStore.isAuthenticated && this.props.children}
// 				{!this.props.rootStore!.authStore.isAuthenticated &&
// 					<>
// 						<Container maxWidth="md">
// 							<Box className="page-title">
// 								<Typography variant="h4">
// 									Inloggen vereist
// 								</Typography>
// 							</Box>
// 						</Container>
// 					</>}
// 			</>
// 		);
// 	}

// 	componentDidMount() {
// 		if (!this.props.rootStore!.authStore.isAuthenticated) {
// 			//this.props.rootStore!.routerStore.push(`/signin?return=${window.location.href}`);
// 		}
// 	}
// }

// export default withStyles(styles)(AuthenticatedArea);

import React, { useState } from 'react';
import { Box, Container, Typography, withTheme, Button } from '@material-ui/core';
import { Pebble } from '../../../models/Pebble';
import { inject } from 'mobx-react';
import RootStore from '../../../stores/RootStore';
import { LogDialog } from './LogDialog';

interface IProps {
  rootStore?: RootStore;
  pebble: Pebble;
}

const PebbleFound = withTheme(
  inject('rootStore')((props: IProps) => {
    const [isLogDialogOpen, setIsLogDialogOpen] = useState<boolean>(false);

    const StoneDetails = () => (
      <Box display="flex" flexDirection="row">
        <Box flexGrow={1}>
          <img
            src={props.pebble.pictures[0]}
            alt="steen"
            style={{
              maxWidth: '100%'
            }}
          />
        </Box>
      </Box>
    );

    const onYesClick = () => setIsLogDialogOpen(true);
    const onNoClick = () => props.rootStore!.routerStore.push('/');

    const onLogDialogClose = () => {
      setIsLogDialogOpen(false);
      if (props.rootStore?.authStore.isAuthenticated) {
        props.rootStore?.routerStore.push(`/my/found-pebbles`);
      } else {
        props.rootStore?.routerStore.push(`/`);
      }
    };

    return (
      <Box pt={2} pb={2}>
        <Container
          maxWidth="md"
          style={{
            textAlign: 'center'
          }}
        >
          <Box
            style={{
              textAlign: 'center'
            }}
          >
            <Typography variant="h3" component="h1">
              Gefeliciteerd!
            </Typography>
            <Typography>
              je hebt <strong>{props.pebble.title}</strong> gevonden
            </Typography>
          </Box>
          <Box mt={2}>
            <StoneDetails />
          </Box>
          <Box pt={1}>
            <Typography>{props.pebble.description}</Typography>
          </Box>
          <Box mt={6}>
            <Typography paragraph={true}>De kunstenaar vindt het leuk als je de vondst wilt loggen.</Typography>
            <Typography>We vragen je naar de locatie waar je de steen gevonden hebt, je waardeing voor de steen, en je hebt de mogelijkheid een berichtje achter te laten.</Typography>
          </Box>
          <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
            <Box>
              <Button variant="contained" color="primary" onClick={onYesClick}>
                Ja, ik wil Loggen
              </Button>
            </Box>
            <Box ml={2}>
              <Button variant="text" onClick={onNoClick}>
                Nee, ik doe niet mee
              </Button>
            </Box>
          </Box>
          <LogDialog open={isLogDialogOpen} pebbleId={props.pebble.id} onClose={onLogDialogClose} />
        </Container>
      </Box>
    );
  })
);

export { PebbleFound };

import axios, { AxiosError } from 'axios';
import axiosInstance from '../lib/Axios';
import * as qs from 'qs';
import { ErrorResponseCodes } from '../abstractions/ErrorResponseCodes';
import { HttpStatusCodes } from '../abstractions/HttpStatusCodes';
import { PebbleNotFoundError, UserNotActivatedError, UserWithSameEmailAlreadyExistsError } from '../lib/Errors';

export type SignUpUserData = {
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  email: string;
  password: string;
};
export default class TransportLayer {
  // AUTH
  signIn = async (username: string, password: string) : Promise<any> => {
    try {
      const res = await axiosInstance({
        method: 'post',
        url: `/auth.signin`,
        data: qs.stringify({
          username: username,
          password: password
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorCode = (err as AxiosError<any>)?.response?.data.errorCode;
        if (errorCode === ErrorResponseCodes.USER_NOT_ACTIVATED) {
          throw new UserNotActivatedError();
        }
      }      
      throw err;
    }
  };

  renewToken = async () : Promise<any> => {
    try {
      const res = await axiosInstance.post<any>('/auth.renewtoken');
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // USER

  signUp = async (user: SignUpUserData): Promise<string> => {
    try {
      const response = await axiosInstance.post<any>('/users', user);
      return response.data.id;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorCode = (err as AxiosError<any>)?.response?.data.errorCode;
        if (errorCode === ErrorResponseCodes.USER_WITH_SAME_USERNAME_EXISTS) throw new UserWithSameEmailAlreadyExistsError();
      }
      throw err;
    }
  };

  activateUser = async (activationId: string): Promise<boolean> => {
    try {
      const response = await axiosInstance.patch(`/user-activations/${activationId}`);
      return response.status === 204;
    } catch (err) {
      console.error('Failed to activate account.', err);
      return false;
    }
  };

  isUserActive = async (userId: string): Promise<boolean> => {
    try {
      const response = await axiosInstance.get<any>(`/users/${userId}/is-active`);
      return response.data.isActive;
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  };

  requestPasswordReset = async (email: string) => {
    try {
      await axiosInstance.post<any>('/password-resets', {
          email: email
        });
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  };

  getPasswordReset = async (id: string) => {
    try {
      const res = await axiosInstance.get<any>(`/password-resets/${id}`);
      return {
        id: id,
        expired: res.data ? res.data.expired : true
      };
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  resetPassword = async (id: string, password: string) => {
    try {
      const res = await axiosInstance.patch(`/password-resets/${id}`, {
        password: password
      });
      return res.status === 204;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  requestActivation = async (username:string) => {
    try {
      await axiosInstance.post('/user-activations', {
        username: username
      });
    } catch (err) {
      console.error('Failed to request activation', err);
      throw err;
    }
  } 

  getUser = async (userId: string) => {
    try {
      const res = await axiosInstance.get(`/users/${userId}`);
      return res.data;
    } catch (err) {
      console.error(`Failed to retrieve user:${userId}`, err);
      throw err;
    }
  };

  // PEBBLE
  fetchPebbleByCode = async (code: string) => {
    try {
      const res = await axiosInstance.get<any>(`/pebbles?code=${code}`);
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const statusCode = (err as AxiosError<any>)?.response?.status;
        if (statusCode === HttpStatusCodes.NOT_FOUND) throw new PebbleNotFoundError();
      }
      console.error(`Failed to retrieve pebble:${code}`, err);
      throw err;
    }
  };

  fetchPebbleCode = async (pebbleId: string): Promise<string> => {
    try {
      console.log('#### get pebble by code');
      const res = await axiosInstance.get<any>(`/pebbles/${pebbleId}/code`);
      return res.data.code;
    } catch (err) {
      console.error(`Failed to retrieve code for pebble:${pebbleId}`, err);
      return '';
    }
  };

  fetchPebbleById = async (pebbleId: string) => {
    try {
      const res = await axiosInstance.get(`/pebbles/${pebbleId}`);
      return res.data;
    } catch (err) {
      console.error(`Failed to retrieve pebble:${pebbleId}`, err);
      throw err;
    }
  };

  fetchPebblesLastFound = async (count: number): Promise<any[]> => {
    try {
      const res = await axiosInstance.get(`/pebbles?last-found=${count}`);
      return res.data;
    } catch (err) {
      console.error(`Failed to retrieve last found pebbles`);
      throw err;
    }
  };

  fetchPebblesForUser = async (userId: string): Promise<any[]> => {
    try {
      const res = await axiosInstance.get(`/pebbles?user-id=${userId}`);
      return res.data;
    } catch (err) {
      console.error(`Failed to retrieve pebbles for user:${userId}`, err);
      throw err;
    }
  };

  fetchPebbleMap = async (pebbleId: string): Promise<any[]> => {
    try {
      const res = await axiosInstance.get(`/pebbles/${pebbleId}/map`);
      return res.data;
    } catch (err) {
      console.error('Failed to retrieve ');
      throw err;
    }
  };

  createPebble = async (pebble: { id: string; title: string; description: string | undefined }): Promise<any> => {
    const res = await axiosInstance.post(`/pebbles`, pebble);
    return res.data;
  };

  uploadPicture = async (pebbleId: string, file: File): Promise<string> => {
    const data = new FormData();
    data.append('picture', file);
    const res = await axiosInstance.post(`/pebbles/${pebbleId}/pictures`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res.headers.location;
  };

  // PROFILE
  fetchProfileFoundPebbles = async (userId: string, page: number, pageSize: number): Promise<any[]> => {
    try {
      const res = await axiosInstance.get(`/profiles/${userId}/found-pebbles?page=${page}&page-size=${pageSize}`);
      return res.data;
    } catch (err) {
      console.error(`Failed to retrieve found pebbled for user:${userId}`);
      throw err;
    }
  };

  // LOG
  fetchLogs = async (pebbleId: string, page: number, pageSize: number) => {
    try {
      const res = await axiosInstance.get<any>(`/pebbles/${pebbleId}/logs?page=${page}&page-size=${pageSize}`);
      return res.data;
    } catch (err) {
      console.error(`Failed to retrieve logs for pebble:${pebbleId}`, err);
      throw err;
    }
  };

  createLog = async (
    pebbleId: string,
    log: {
      message: string | undefined;
      rating: number;
      position: {
        latitude: number;
        longitude: number;
        country: string;
        countryCode: string;
        municipality: string;
        postalCode: string;
        region: string;
        street: string;
        houseNumber: string;
        neighbourhood: string;
        state: string;
        city: string;
      };
    }
  ): Promise<any> => {
    const res = await axiosInstance.post(`/pebbles/${pebbleId}/logs`, log);
    return res.data;
  };
}

import { Box, Button, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { compose, defaultProps } from 'recompose';

const useStyles = makeStyles((theme) => ({
  input: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1
  },
  previewContainer: {
    height: '30vh'
  },
  deleteButtonContainer: {
    position: 'absolute',
    right: 0
  },
  deleteButton: {
    margin: theme.spacing(2),
    color: theme.palette.error.main,
    backgroundColor: 'white'
  }
}));

const ERROR = {
  NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
  FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE'
};

interface IProps {
  className: string;
  buttonText: string;
  file: File | undefined;
  accept: string;
  onChange: (files: File | undefined, pictures: string | undefined) => void;
  onDelete: () => void;
  noImageSelectedLabel: string;
  imgExtension: string[];
  maxFileSize: number;
  fileSizeError: string;
  fileTypeError: string;
  defaultImages: [];
}

const ImageUploader = compose<IProps, any>(
  defaultProps({
    buttonText: 'Choose images',
    onChange: (_file: File, _picture: string) => {
      // TODO: implement onChange in ImageUploader
    },
    onDelete: () => {
      // TODO: implement onDelete in ImageUploader
    },
    withPreview: true,
    accept: 'image/*',
    noImageSelectedLabel: 'No image select',
    imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
    maxFileSize: 5242880,
    fileSizeError: ' file size is too big',
    fileTypeError: ' is not a supported file extension'
  })
)((props: IProps) => {
  const classes = useStyles();
  const inputElement = useRef(null);
  const [picture, setPicture] = useState<string>();
  const [fileError, setFileError] = useState<any | undefined>();

  const triggerFileUpload = () => {
    (inputElement.current! as any).click();
  };

  const hasExtension = (fileName: string) => {
    const pattern = '(' + props.imgExtension.join('|').replace(/\./g, '\\.') + ')$';
    return new RegExp(pattern, 'i').test(fileName);
  };

  const resizeImage = (file: File, callback: BlobCallback) => {
    // (dataUrl: string) => void) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result!;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = 1000;
        const MAX_HEIGHT = 1000;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        //console.log("### size", width, height);
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx!.drawImage(img, 0, 0, width, height);
        //const dataUrl = canvas.toDataURL(file.type);
        canvas.toBlob(callback, file.type);
        //callback(dataUrl);
        //console.log('### durl', dataUrl);
      };

      //canvas.toBlob(callback, file.type);
    };
    reader.readAsDataURL(file);
  };

  const processFile = (file: File | undefined) => {
    setPicture(undefined);
    setFileError(undefined);
    if (!file) return;

    let fileError = {
      name: file.name
    };

    // Check for file extension
    if (!hasExtension(file.name)) {
      fileError = Object.assign(fileError, {
        type: ERROR.NOT_SUPPORTED_EXTENSION
      });
      setFileError(fileError);
      return;
    }

    // Resize image
    resizeImage(file, (blob) => {
      //const newFile = new File([blob!], file.name, { type: file.type });

      const pictureUrl = URL.createObjectURL(blob);
      setPicture(pictureUrl);
      const newFile = new File([blob!], file.name, { type: file.type });
      props.onChange(newFile, pictureUrl);
    });
    // let fr = new FileReader();
    // fr.onload = (e:any) => {
    //     console.log('### loading', e);

    //     // when file has loaded
    //     var img = new Image();
    //     img.src = e.target.result;
    //     img.onload = () => {
    //         const width = img.width;
    //         const height = img.height;

    //         console.log('### size', width, height)

    //         if (width > 1000) {
    //             const newWidth = 1000.0;
    //             const newHeight = height / (width / newWidth);

    //             console.log('### new size', newWidth, newHeight)

    //             const canvas = document.createElement('canvas') as any;
    //             canvas.width = newWidth;
    //             canvas.height = newHeight;
    //             const context = canvas.getContext('2d');
    //             context.drawImage(img, 0, 0, newWidth, newHeight);
    //             canvas.toBlob((blob:any) => {
    //                 console.log('### img', file.size);
    //                 console.log('### blob', blob);
    //             }, file.type);
    //         }
    //     }
    // };
    // fr.readAsDataURL(file);
    // console.log('### reader');

    // Check for file size
    // if (file.size > props.maxFileSize) {
    //     fileError = Object.assign(fileError, {
    //         type: ERROR.FILESIZE_TOO_LARGE
    //     });
    //     setFileError(fileError);
    //     return;
    // }

    //const pictureUrl = URL.createObjectURL(file);
    // setPicture(pictureUrl);

    // props.onChange(file, pictureUrl);
  };

  const onDropFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    processFile(files ? files[0] : undefined);
  };

  const onUploadClick = (e: any) => {
    // Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
    e.target.value = null;
  };

  const onDelete = () => {
    setPicture(undefined);
    //setFile(undefined);
    setFileError(undefined);
    props.onChange(undefined, undefined);
  };

  return (
    <>
      <Box>
        {!picture && (
          <Paper variant="outlined" className={classes.previewContainer}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              {fileError && (
                <Box color="error.main" mt={2} mb={2}>
                  <Typography>
                    {fileError.name} {fileError.type === ERROR.FILESIZE_TOO_LARGE ? props.fileSizeError : props.fileTypeError}
                  </Typography>
                </Box>
              )}
              <Box mb={2}>
                <Button variant="contained" onClick={triggerFileUpload}>
                  {props.buttonText}
                </Button>
              </Box>
              <Box>
                <Typography color="textSecondary">{props.noImageSelectedLabel}</Typography>
              </Box>
            </Box>
          </Paper>
        )}
        {picture && (
          <Box>
            <Box style={{ position: 'relative' }}>
              <Box className={classes.deleteButtonContainer}>
                <IconButton className={classes.deleteButton} onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center">
              <img
                src={picture}
                alt="preview"
                style={{
                  maxWidth: '100%',
                  maxHeight: '40vh'
                }}
              />
            </Box>
          </Box>
        )}
        <input className={classes.input} type="file" ref={inputElement} name="file" multiple={false} onChange={onDropFile} onClick={onUploadClick} accept={props.accept} />
      </Box>
    </>
  );
});

export { ImageUploader };

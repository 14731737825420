import React, { useState } from 'react';
import { Typography, withTheme } from '@material-ui/core';
import { PebbleLoader } from '../../components/loaders/PebbleLoader';
import { Pebble } from '../../models/Pebble';
import RootStore from '../../stores/RootStore';
import { PebbleFound } from './components/PebbleFound';
import { inject } from 'mobx-react';
import { SimpleDialog } from '../../components/SimpleDialog';

interface IProps {
  rootStore?: RootStore;
  code: string;
}

const Lookup = withTheme(
  inject('rootStore')((props: IProps) => {
    const [pebble, setPebble] = useState<Pebble>();
    const [showNotFound, setShowNotFound] = useState(false);
    const onPebbleLoaded = (pebble: Pebble) => {
      setPebble(pebble);
    };
    const onPebbleNotFound = () => {
      setShowNotFound(true);
    };
    const onNotFoundClosed = () => {
      props.rootStore?.routerStore.push('/');
    };

    return (
      <>
        <PebbleLoader code={props.code} onLoaded={onPebbleLoaded} onNotFound={onPebbleNotFound}>
          {pebble && <PebbleFound pebble={pebble}></PebbleFound>}
        </PebbleLoader>
        <SimpleDialog title="Niet gevonden" open={showNotFound} showCancelButton={false} onOk={onNotFoundClosed}>
          <Typography>Helaas, de steen is niet gevonden.</Typography>
        </SimpleDialog>
      </>
    );
  })
);

export { Lookup };

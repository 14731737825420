import React from 'react';
import { inject } from 'mobx-react';
import { Box, Drawer, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core';
import RootStore from '../stores/RootStore';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default
  }
}));

interface IProps {
  rootStore?: RootStore;
  open: boolean;
  onClose?(): void;
}

const MainMenu = inject('rootStore')((props: IProps) => {
  const classes = useStyles();

  const close = () => {
    if (props.onClose) props.onClose();
  };
  const onClose = () => close;
  const onCloseClick = close;
  const onSignOutClick = async () => {
    await props.rootStore?.authStore.signOut();
    props.rootStore?.routerStore.push('/');
    close();
  };
  const onMyPebblesClick = () => {
    props.rootStore!.routerStore.push('/my/pebbles');
    close();
  };
  const onFoundPebbledClick = () => {
    props.rootStore!.routerStore.push('/my/found-pebbles');
    close();
  };

  return (
    <Drawer open={props.open} onClose={onClose} anchor="right">
      <Box display="flex" flexDirection="row" alignItems="center" style={{ width: '300px' }} p={2} className={classes.header}>
        <Box mr={2}>
          <AccountCircleIcon />
        </Box>
        <Box flexGrow={1}>
          <Typography variant="body1" component="h1">
            {props.rootStore!.authStore.user?.firstName}
          </Typography>
        </Box>
        <Box>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={onCloseClick}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box mt={2}>
        <MenuItem onClick={onMyPebblesClick}>Mijn stenen</MenuItem>
        <MenuItem onClick={onFoundPebbledClick}>Gevonden stenen</MenuItem>
        <hr />
        <MenuItem onClick={onSignOutClick}>Uitloggen</MenuItem>
      </Box>
    </Drawer>
  );
});

export { MainMenu };

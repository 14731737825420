import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Button, Container, IconButton } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import RootStore from '../stores/RootStore';
import { SignInDialog } from './SignInDialog';
import MenuIcon from '@material-ui/icons/Menu';
import { MainMenu } from './MainMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.background.default
  },
  button: {
    color: 'white'
  }
}));

interface IProps {
  rootStore?: RootStore;
}

const MyAppBar = inject('rootStore')(
  observer((props: IProps) => {
    const classes = useStyles();
    const [signInDialogOpen, setSignInDialogOpen] = useState<boolean>(false);
    const [mainMenuOpen, setMainMenuOpen] = useState(false);

    const onLogoClick = () => props.rootStore?.routerStore.push('/');
    const onLoginClick = () => setSignInDialogOpen(true);
    const onSignInDialogClose = () => {
      setSignInDialogOpen(false);
      if (props.rootStore?.authStore.isAuthenticated) {
        props.rootStore?.routerStore.push('/my');
      }
    };

    const LoginButton = () => (
      <Button aria-controls="login" aria-haspopup="true" onClick={onLoginClick} className={classes.button}>
        Inloggen
      </Button>
    );

    const onMenuClick = () => {
      setMainMenuOpen(!mainMenuOpen);
    };
    const onMainMenuClose = () => {
      setMainMenuOpen(false);
    };
    return (
      <>
        <AppBar position="fixed" className={classes.root} elevation={0}>
          <Container maxWidth="md">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ height: '64px' }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" style={{ height: '64px' }}>
                <Box>
                  <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="rubble logo" height="50px" className={`pointer`} onClick={onLogoClick} />
                </Box>
              </Box>

              {!props.rootStore?.authStore.isAuthenticated && <LoginButton />}
              {/* {props.rootStore?.authStore.isAuthenticated && <LogoutButton />} */}

              {props.rootStore?.authStore.isAuthenticated && (
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
                  <MenuIcon />
                </IconButton>
              )}
            </Box>
          </Container>
        </AppBar>
        <MainMenu open={mainMenuOpen} onClose={onMainMenuClose} />
        <SignInDialog open={signInDialogOpen} onClose={onSignInDialogClose} />
      </>
    );
  })
);

export { MyAppBar };

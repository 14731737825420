import React from 'react';
import { Container } from '@material-ui/core';

interface IProps {}

const CustContainer = (props: React.PropsWithChildren<IProps>) => (
  <Container maxWidth="md" disableGutters>
    <>{props.children}</>
  </Container>
);

export { CustContainer };

import { Box, TextField, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { inject } from 'mobx-react';
import React, { useState } from 'react';
import { SimpleDialog } from '../../../components/SimpleDialog';
import { WizardDialog } from '../../../components/wizards/WizardDialog';
import RootStore from '../../../stores/RootStore';
import { LeafletMapComponent, Position } from './LeafletMapComponent';
import { LogCompletedDialog } from './LogCompletedDialog';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface Validations {
  position?: boolean;
  rating?: boolean;
  message?: boolean;
}

interface IProps {
  rootStore?: RootStore;
  pebbleId: string;
  open: boolean;
  onClose: () => void;
}

const LogDialog = inject('rootStore')((props: IProps) => {
  const [position, setPosition] = useState<Position | undefined>(undefined);
  const [rating, setRating] = useState<number>(0);
  const [message, setMessage] = useState<string>();
  const [validations, setValidations] = useState<Validations>();
  const [createAccountOrSignInDialogOpen, setCreateAccountOrSignInDialogOpen] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const onValidateStep = (index: number) => {
    switch (index) {
      case 0: {
        const valid = position !== undefined;
        setValidations({ ...validations, position: valid });
        return valid;
      }
      case 1: {
        const valid = rating > 0;
        setValidations({ ...validations, rating: valid });
        return valid;
      }
    }
    return true;
  };
  const onLocationChange = (position: Position) => {
    setPosition(position);
    console.log(position);
    setValidations({ ...validations, position: true });
  };
  const onRatingChange = (e: React.ChangeEvent<any>, value: number | null) => {
    setRating(value !== null ? value : 0);
    setValidations({ ...validations, rating: value !== null && value > 0 });
  };
  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value);
  const onSubmit = async () => {
    if (props.rootStore?.authStore.isAuthenticated) {
      await createLogAndShowSucessDialog();
    } else {
      setCreateAccountOrSignInDialogOpen(true);
    }
  };
  const onCreateAccountOrSignInDialogClose = async () => {
    setCreateAccountOrSignInDialogOpen(false);
    await createLogAndShowSucessDialog();
  };
  const onSuccessDialogOkClick = () => props.onClose();

  const createLogAndShowSucessDialog = async () => {
    await props.rootStore?.logStore.createLog(props.pebbleId, {
      message: message,
      rating: rating,
      position: {
        longitude: position!.longitude,
        latitude: position!.latitude,
        country: position!.country,
        countryCode: position!.countryCode,
        city: position!.city,
        street: position!.street,
        houseNumber: position!.houseNumber,
        municipality: position!.municipality,
        region: position!.region,
        neighbourhood: position!.neighbourhood,
        postalCode: position!.postalCode,
        state: position!.street
      }
    });
    setSuccessDialogOpen(true);
  };

  return (
    <>
      <WizardDialog
        title="Loggen"
        finishButtonText="Loggen"
        fullScreen={false}
        open={props.open && !createAccountOrSignInDialogOpen && !successDialogOpen}
        onClose={props.onClose}
        onSubmit={onSubmit}
        onValidateStep={onValidateStep}
        fullWidth={true}
        maxWidth="md"
      >
        <Box>
          <LeafletMapComponent position={position} onChange={onLocationChange} />
          {validations && validations.position !== undefined && !validations.position && (
            <Box mt={2} color="error.main">
              <Typography>Geen locatie geselecteerd. Selecteer de locatie waar je de steen heb gevonden door op de kaart hierboven te klikken.</Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant="h6" component="span">
              Welke waardering geef je aan de steen?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" m={2}>
            <Rating precision={0.5} onChange={onRatingChange} value={rating} style={{ fontSize: '3rem' }} />
          </Box>
          {validations && validations.rating !== undefined && !validations.rating && (
            <Box mt={2} color="error.main">
              <Typography>Geef een rating op door op de sterren te klikken.</Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant="h6" component="span">
              Bericht aan de kunstenaar voor in het logboek
            </Typography>
          </Box>
          <TextField name="message" fullWidth multiline={true} rows={10} variant="filled" value={message} onChange={onMessageChange} />
        </Box>
      </WizardDialog>
      <LogCompletedDialog open={createAccountOrSignInDialogOpen} onClose={onCreateAccountOrSignInDialogClose} />
      <SimpleDialog title="Bedankt" showCancelButton={false} open={successDialogOpen} onOk={onSuccessDialogOkClick}>
        <>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box color="success.main">
              <CheckCircleOutlineIcon style={{ fontSize: '5rem' }} />
            </Box>
          </Box>
          <Typography>{props.rootStore?.authStore?.isAuthenticated ? `${props.rootStore?.authStore.user?.firstName}, bedankt voor het loggen.` : 'Bedankt voor het loggen'}</Typography>
        </>
      </SimpleDialog>
    </>
  );
});

export { LogDialog };

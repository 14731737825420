import { Button, makeStyles, TextField } from '@material-ui/core';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { WizardDialog } from '../../../components/wizards/WizardDialog';
import { Pebble } from '../../../models/Pebble';
import RootStore from '../../../stores/RootStore';
import { ImageUploader } from '../../../components/ImageUploader';
import { SignInDialog } from '../../../components/SignInDialog';

interface IValues {
  title: string | undefined;
  description: string | undefined;
  pictureFile: File | undefined;
  pictureUrl: string | undefined;
}

interface IValidation {
  title: boolean | undefined;
  picture: boolean | undefined;
  authentication: boolean | undefined;
}
interface IProps {
  rootStore?: RootStore;
  open: boolean;
  onClose: () => void;
  onSubmit: (pebble: Pebble) => void;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const GetStartedDialog = inject('rootStore')((props: IProps) => {
  const finishButtontext = 'Registratie afronden';
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [signInDialogOpen, setSignInDialogOpen] = useState<boolean>(false);
  const [values, setValues] = useState<IValues>({
    title: undefined,
    description: undefined,
    pictureFile: undefined,
    pictureUrl: undefined
  });
  const [validations, setValidations] = useState<IValidation>({
    title: undefined,
    picture: undefined,
    authentication: undefined
  });

  useEffect(() => {
    onValidateStep(activeStep);
  }, [values, activeStep]);

  const onClose = () => {
    props.onClose();
  };

  const onActiveStepChange = (index: number) => {
    setActiveStep(index);
  };

  const onValidateStep = (index: number) => {
    switch (index) {
      case 1: {
        const valid = values.title !== undefined && values.title.length > 0;
        setValidations({ ...validations, title: valid });
        return valid;
      }
      case 3: {
        const valid = values.pictureFile !== undefined;
        setValidations({ ...validations, picture: valid });
        return valid;
      }
      case 4: {
        const valid = props.rootStore!.authStore.isAuthenticated;
        setValidations({ ...validations, authentication: valid });
        return valid;
      }
    }
    return true;
  };

  const handleTextChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onPictureChanged = (pictureFile: File, pictureUrl: string) => {
    setValues({ ...values, pictureFile, pictureUrl });
  };

  const onSignIn = () => {
    setSignInDialogOpen(true);
  };

  const onSignInDialogClose = () => {
    setSignInDialogOpen(false);
    onValidateStep(activeStep);
  };

  const onSubmit = async () => {
    const pebble = await props.rootStore!.pebbleStore.createPebble({
      title: values.title ?? '',
      description: values.description
    });
    await pebble.uploadPicture(values.pictureFile!);
    props.onSubmit(pebble);
  };

  return (
    <WizardDialog
      title="Steen registreren"
      open={props.open}
      finishButtonText={finishButtontext}
      onClose={onClose}
      onActiveStepChange={onActiveStepChange}
      onValidateStep={onValidateStep}
      fullWidth={true}
      maxWidth="md"
      disableBackdropClick={true}
      onSubmit={onSubmit}
    >
      <Box>
        <Typography paragraph={true}>Leuk dat je je steen wilt registreren bij Rubble.</Typography>
        <Typography paragraph={true}>We vragen je in de hierop volgende stappen wat informatie over de steen in te voeren, een mooie foto te uploaden en een Rubble accoount aan te maken.</Typography>
        <Typography paragraph={true}>Daarna is het genieten van alle leuke reacties die de vinders achterlaten.</Typography>
      </Box>
      <Box>
        <Typography variant="h6" component="h2">
          Geef een naam op voor je steen
        </Typography>
        <Typography variant="body2" color="textSecondary">
          De naam kan later nog gewijzigd worden.
        </Typography>
        <TextField
          id="title"
          required
          className={classes.textField}
          variant="filled"
          defaultValue={values.title}
          onChange={handleTextChange('title')}
          error={validations.title !== undefined && !validations.title}
          fullWidth
          inputProps={{
            'aria-label': 'naam'
          }}
          helperText={validations.title !== undefined && !validations.title ? 'Je moet een naam opgeven.' : ''}
        />
      </Box>
      <Box>
        <Typography variant="h6" component="h2">
          Geef een korte omschrijving over je steen.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          De omschrijving kan later nog gewijzigd worden.
        </Typography>
        <TextField
          id="description"
          required
          className={classes.textField}
          variant="filled"
          defaultValue={values.description}
          onChange={handleTextChange('description')}
          error={validations.title !== undefined && !validations.title}
          fullWidth
          rows={7}
          multiline={true}
          inputProps={{
            'aria-label': 'omschrijving'
          }}
        />
      </Box>
      <Box>
        <Typography paragraph={true} variant="h6" component="h2">
          Upload je mooiste foto.
        </Typography>
        <Box mt={2} mb={2}>
          <ImageUploader
            buttonText="Selecteer foto"
            onChange={onPictureChanged}
            file={values.pictureFile}
            imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
            label="Maximale grootte 2Mb"
            noImageSelectedLabel="Geen foto geselecteerd. Klik op de knop Selecteer Foto"
            fileSizeError=" is te groot"
            fileTypeError=" is een niet ondersteund formaat"
            maxFileSize={2 * 1024 * 1024}
          />
        </Box>
        {validations.picture !== undefined && !validations.picture && (
          <Box color="error.main">
            <Typography>Een foto is verplicht.</Typography>
          </Box>
        )}
      </Box>
      <Box>
        {!props.rootStore!.authStore.isAuthenticated && (
          <>
            <Typography paragraph={true}>Om je steen registratie af te ronden moet je inloggen of een account aanmaken.</Typography>
            <Box display="flex" flexDirection="row" justifyContent="center" mb={2}>
              <Button variant="contained" color="primary" onClick={onSignIn}>
                Inloggen of account maken
              </Button>
            </Box>
            {validations.authentication !== undefined && !validations.authentication && (
              <Box color="error.main">
                <Typography>Je moet inloggen of een account maken</Typography>
              </Box>
            )}
            <SignInDialog open={signInDialogOpen} onClose={onSignInDialogClose} />
          </>
        )}
        {props.rootStore!.authStore.isAuthenticated && (
          <>
            <Typography paragraph={true}>Nadat je steen registreerd is ontvang je de unieke code voor op je steen.</Typography>
            <Typography paragraph={true}>Klik op de knop '{finishButtontext}' om de registratie af te ronden.</Typography>
          </>
        )}
      </Box>
    </WizardDialog>
  );
});

export { GetStartedDialog };

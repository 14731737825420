import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Button, makeStyles, Typography, TextField, Grid } from '@material-ui/core';
import RootStore from '../stores/RootStore';
import emailValidator from 'email-validator';
import passwordValidator from '../lib/PasswordValidator';
import { UserWithSameEmailAlreadyExistsError } from '../lib/Errors';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

interface IValues {
  email: string;
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

interface IValidations {
  email: boolean | undefined;
  firstName: boolean | undefined;
  lastName: boolean | undefined;
  password: boolean | undefined;
  passwordConfirmation: boolean | undefined;
}

interface IProps {
  rootStore?: RootStore;
  showTitle?: boolean;
  onSuccessful: (userId: string) => void;
  onSignInClick: () => void;
}

type State = 'init' | 'success' | 'email_in_use' | 'unknown_error';

const SignUpForm = inject('rootStore')(
  observer((props: IProps) => {
    const classes = useStyles();
    const [state, setState] = useState<State>('init');
    const [values, setValues] = useState<IValues>({
      email: '',
      firstName: '',
      lastNamePrefix: '',
      lastName: '',
      password: '',
      passwordConfirmation: ''
    });
    const [validations, setValidations] = useState<IValidations>({
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      password: undefined,
      passwordConfirmation: undefined
    });

    const onChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

    const onSignInClick = () => {
      props.onSignInClick();
    };

    const onNextClick = async () => {
      const email = values.email !== undefined && emailValidator.validate(values.email);
      const firstName = values.firstName !== undefined && values.firstName.length > 0;
      const lastName = values.lastName !== undefined && values.lastName.length > 0;
      const password = values.password !== undefined && (passwordValidator.validate(values.password) as boolean);
      const passwordConfirmation = values.passwordConfirmation !== undefined && values.password === values.passwordConfirmation;

      setValidations({
        ...validations,
        email,
        firstName: firstName,
        lastName: lastName,
        password,
        passwordConfirmation
      });

      if (email && firstName && lastName && password && passwordConfirmation) {
        try {
          const userId = await props.rootStore!.accountStore.register({
            firstName: values.firstName,
            lastNamePrefix: values.lastNamePrefix,
            lastName: values.lastName,
            email: values.email,
            password: values.password
          });
          setState('success');
          props.onSuccessful(userId);
        } catch (err) {
          setState(err instanceof UserWithSameEmailAlreadyExistsError ? 'email_in_use' : 'unknown_error');
        }
      }
    };

    return (
      <Box>
        {(props.showTitle === undefined || props.showTitle) && (
          <Box mb={2}>
            <Typography variant="h4" component="h2">
              Je Rubble-account maken
            </Typography>
          </Box>
        )}
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="firstname"
                label="Voornaam"
                autoComplete="off"
                onChange={onChange('firstName')}
                className={classes.textField}
                error={validations.firstName !== undefined && !validations.firstName}
                fullWidth
                variant="filled"
                required
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField id="lastName" label="Tussenvoegsel" autoComplete="off" onChange={onChange('lastNamePrefix')} className={classes.textField} fullWidth variant="filled" />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="lastName"
                label="Achternaam"
                autoComplete="off"
                onChange={onChange('lastName')}
                className={classes.textField}
                error={validations.lastName !== undefined && !validations.lastName}
                fullWidth
                variant="filled"
                required
              />
            </Grid>
          </Grid>
          <TextField
            id="email"
            label="Email"
            autoComplete="off"
            onChange={onChange('email')}
            className={classes.textField}
            error={validations.email !== undefined && !validations.email}
            fullWidth
            variant="filled"
            required
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="password"
                label="Wachtwoord"
                type="password"
                autoComplete="off"
                onChange={onChange('password')}
                className={classes.textField}
                error={validations.password !== undefined && !validations.password}
                helperText={
                  validations.password !== undefined && !validations.password ? 'Minimaal 8 tekens lang, maximaal 30 tekens lang, tenminste 1 hoofdletter, 1 kleine letter en een cijfer.' : ''
                }
                fullWidth
                variant="filled"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="passwordConfirmation"
                label="Wachtwoord controle"
                type="password"
                autoComplete="off"
                onChange={onChange('passwordConfirmation')}
                className={classes.textField}
                error={validations.passwordConfirmation !== undefined && !validations.passwordConfirmation}
                fullWidth
                variant="filled"
                required
              />
            </Grid>
          </Grid>
        </Box>

        {(state === 'email_in_use' || state === 'unknown_error') && (
          <Box>
            <Box color="error.main" p={2}>
              {state === 'email_in_use' && <Typography>Email adres is al in gebruik. Log in of gebruik de optie 'wachtwoord vergeten' als je je wachtwoord niet meer weet.</Typography>}
              {state === 'unknown_error' && <Typography>Er is iets fout gegaan. Excuses voor het ongemak. Probeer het later nogmaals.</Typography>}
            </Box>
          </Box>
        )}

        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={4}>
          <Box>
            <Button color="secondary" onClick={onSignInClick}>
              Ik wil inloggen
            </Button>
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={onNextClick}>
              Volgende
            </Button>
          </Box>
        </Box>
      </Box>
    );
  })
);

export { SignUpForm };

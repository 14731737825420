import { Settings as LuxonSettings } from 'luxon';

class Settings {
  apiBaseUrl: string = '';
  webBaseUrl: string = '';
}

/* Local */
// const settings: Settings = {
//   apiBaseUrl: 'http://localhost:3002',
//   webBaseUrl: 'http://localhost:3000'
// };

/* Local IP cable */
// const settings: Settings = {
// 	apiBaseUrl: 'http://192.168.0.52:3002',
//    	webBaseUrl: 'http://192.168.0.52:3000',
// }

/* Development */
const settings: Settings = {
	apiBaseUrl: 'https://api.dev.rubble.app',
  webBaseUrl: 'https://dev.rubble.app',
}

/* Production */
/*const settings: Settings = {
	apiBaseUrl: 'https://api.rubble.app',
	webBaseUrl: 'https://rubble.app'
}*/

// Luxon configuration
LuxonSettings.defaultLocale = 'nl';

export default settings;

import { inject, observer } from 'mobx-react';
import { CustPaper } from '../customizations/CustPaper';
import { Box, Button, Typography } from '@material-ui/core';
import { PebbleMap } from '../PebbleMap';
import RootStore from '../../stores/RootStore';

interface IProps {
  rootStore?: RootStore;
  pebbleId: string;
}

const PebbleZwerftochtWidget = inject('rootStore')(
  observer((props: IProps) => {
    const onRegisterClick = () => {
      props.rootStore?.routerStore.push('/sign-up', { return: true });
    };

    return (
      <CustPaper>
        <Box mb={2}>
          <Typography variant="h4" component="h2">
            Zwerftocht
          </Typography>
        </Box>
        {props.rootStore?.authStore.isAuthenticated && (
          <Box style={{ height: '50vh', minHeight: '300px' }} mt={2}>
            <PebbleMap pebbleId={props.pebbleId} />
          </Box>
        )}
        {!props.rootStore?.authStore.isAuthenticated && (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
              <Typography>Wil je de zwerftocht van deze steen zien? Registreer je dan nu gratis.</Typography>
            </Box>
            <Box mt={3} mb={3}>
              <Button variant="contained" color="primary" onClick={onRegisterClick}>
                Maak gratis Rubble account
              </Button>
            </Box>
          </Box>
        )}
      </CustPaper>
    );
  })
);

export { PebbleZwerftochtWidget };

import React from 'react';
import { observer } from 'mobx-react';
import { withTheme } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  title: string;
  okButtonText?: string;
  cancelButtonText?: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  open: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  scroll?: 'body' | 'paper';
  disableBackdropClick?: boolean;
  showCloseIcon?: boolean;
  showCancelButton?: boolean;
}

const SimpleDialog = withTheme(
  observer((props: React.PropsWithChildren<IProps>) => {
    const handleCancel = () => {
      if (props.onCancel) props.onCancel();
    };

    const handleOk = () => {
      if (props.onOk) props.onOk();
    };

    return (
      <>
        <Dialog
          fullScreen={props.fullScreen}
          onClose={handleCancel}
          open={props.open}
          aria-labelledby="dialog"
          maxWidth={props.maxWidth}
          fullWidth={props.fullWidth}
          scroll={props.scroll}
          disableBackdropClick={props.disableBackdropClick}
        >
          <DialogTitle>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flexGrow={1}>{props.title}</Box>
              {props.showCloseIcon && (
                <Box>
                  <IconButton aria-label="close" color="primary" onClick={handleCancel}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>{props.children}</DialogContent>
          <DialogActions>
            {props.showCancelButton && (
              <Button variant="text" onClick={handleCancel}>
                {props.cancelButtonText ?? 'Annuleren'}
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={handleOk}>
              {props.okButtonText ?? 'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  })
);

SimpleDialog.defaultProps = {
  showCancelButton: true
};

export { SimpleDialog };
